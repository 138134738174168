define("discourse/plugins/discourse-membership-indicator/discourse/initializers/extend-for-membership-indicator", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-membership-indicator",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.10", api => {
        api.decorateWidget('post-avatar:after', helper => {
          let imgs = helper.getModel().membership_indicator.split(',');
          for (var i = 0; i < imgs.length; i++) {
            imgs[i] = helper.rawHtml('<img style="width: 45px;" src="' + imgs[i] + '">');
          }
          if (imgs.length > 0) {
            return helper.h('span', imgs);
          } else {
            return '';
          }
        });
      });
    }
  };
});